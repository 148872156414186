import type { JSONSchema7 } from 'json-schema';
import get from 'lodash/get';
import has from 'lodash/has';
import isString from 'lodash/isString';
import memoize from 'lodash/memoize';

import { invariant } from '../utils';
import { FilterDataType } from '../view-to-sql/constants';
import {
  PROPERTY_RANK_DEFAULT,
  PROPERTY_RANK_PRIMARY,
  PROPERTY_RANK_RELATIONSHIP,
  PROPERTY_RANK_UUID,
  importableEntityRelationshipsMap,
} from './constants';
import {
  AvailableXClarifyParams,
  ClarifyJSONSchema7,
  FieldMetadata,
  FieldMetadataJoinInfo,
  JSON_SCHEMA_COLLECTION_TYPES,
  JSON_SCHEMA_DATE_FORMATS,
  JSON_SCHEMA_ENUM_TYPES,
  JSON_SCHEMA_PRIMITIVE_TYPES,
  JSON_SCHEMA_SOCIAL_HANDLE_TYPES,
  SchemaFieldType,
  xClarifyEnumMetadata,
} from './types';

export type JsonSchemaHelperParams = {
  objectOrSchemaId: string;
  schemas: Map<string, JSONSchema7>;
  join?: FieldMetadataJoinInfo;
};

type GetFieldsParams = {
  includeHidden?: boolean;
  includeArchived?: boolean;
  resolveFields?: boolean;
};

export class JsonSchemaHelper {
  readonly schemaId: string;
  readonly objectName: string;
  readonly schema: ClarifyJSONSchema7;
  readonly schemas: Map<string, JSONSchema7>;
  private readonly _join?: FieldMetadataJoinInfo;

  constructor({ schemas, objectOrSchemaId, join }: JsonSchemaHelperParams) {
    const { schemaId, objectName } =
      this.parseObjectOrSchemaId(objectOrSchemaId);

    this.schemaId = schemaId;
    this.objectName = objectName;
    this.schemas = schemas;
    this._join = join;

    const schema = this.schemas.get(this.schemaId);
    if (!schema) {
      throw new Error(`Schema ${this.schemaId} not found in provided schemas.`);
    }
    this.schema = schema;
  }

  public deriveInstance(
    objectName: string,
    options?: Omit<JsonSchemaHelperParams, 'schemas' | 'objectOrSchemaId'>,
  ): JsonSchemaHelper {
    return memoize(
      () =>
        new JsonSchemaHelper({
          schemas: this.schemas,
          objectOrSchemaId: objectName,
          ...options,
        }),
    )();
  }

  private parseObjectOrSchemaId(objectOrSchemaId: string) {
    if (!objectOrSchemaId) {
      throw new Error('schemaName is required');
    }

    const baseSchemaUrl = 'https://getclarify.ai/schemas';
    if (objectOrSchemaId.startsWith(baseSchemaUrl)) {
      return {
        schemaId: objectOrSchemaId,
        objectName: objectOrSchemaId.replace(`${baseSchemaUrl}/entities/`, ''),
      };
    } else {
      return {
        schemaId: `${baseSchemaUrl}/entities/${objectOrSchemaId}`,
        objectName: objectOrSchemaId,
      };
    }
  }

  public getField(fieldNameOrPath: string): FieldMetadata {
    const split = fieldNameOrPath.split('.');
    const fieldName = split[split.length - 1] as string;

    // TODO: (AK) revisit the logic below, it breaks assumption that the helper instance
    //  dedicated to a single object schema
    if (split.length > 1) {
      const objectName = split[0] as string;
      const objectSchema = this.deriveInstance(objectName);
      return objectSchema.getField(fieldName);
    }

    const schema = this.getFieldSchema(fieldName);
    const type = this.normalizeFieldType(schema);

    const isPrimitiveType = JSON_SCHEMA_PRIMITIVE_TYPES.has(type);
    const isCollectionType = JSON_SCHEMA_COLLECTION_TYPES.has(type);
    const isSocialHandleType = JSON_SCHEMA_SOCIAL_HANDLE_TYPES.has(type);
    const isDateType = JSON_SCHEMA_DATE_FORMATS.has(type);
    const isEnumType =
      JSON_SCHEMA_ENUM_TYPES.has(type) ||
      Boolean(schema.enum) ||
      has(schema, 'properties.items.items.enum');
    const isPrimary = Boolean(schema.xClarifyPrimary);

    let metadata: FieldMetadata = {
      type,
      schema,
      name: fieldName,
      isPrimitiveType,
      isCollectionType,
      isSocialHandleType,
      isDateType,
      isObjectType: !isCollectionType && type.includes('/core/'),
      isPrimary,
      isReadOnly: Boolean(schema.readOnly),
      isHidden: Boolean(schema.xClarifyHidden),
      isArchived: Boolean(schema.xClarifyArchived),
      isRestricted: Boolean(schema.xClarifyRestricted),
      isRelationship: false,
      isPrimaryImage: Boolean(schema.xClarifyPrimaryImage),
      isProtected: Boolean(schema.xClarifyProtected),
      isCompany: this.objectName === 'company',
      isSecure: Boolean(schema.xClarifySecure),
      isEnumType,
      // computed below
      isCollectionTypeLike: false,
      relationship: schema.xClarifyRelationship,
      title: schema.title ?? '',
      path: `${this.objectName}.${fieldName}`,
      schemaName: this.objectName,
      dataType: FilterDataType.String,
      width: 400,
      sortedInView: schema.xClarifyPresentation?.sortedInView,
      isViewDefault:
        isPrimary || Boolean(schema.xClarifyPresentation?.includedInView),
      presentation: schema.xClarifyPresentation,
    };

    if (this._join) {
      metadata = {
        ...metadata,
        join: this._join,
        isRelationship: true,
      };
    }

    if (isEnumType) {
      metadata.enum = this.getEnumData(metadata);
    }

    metadata.isCollectionTypeLike =
      metadata.isCollectionType || Boolean(metadata.enum?.multiselect);
    metadata.dataType = this.getDataType(metadata);
    metadata.width = this.getColumnWidth(metadata);

    return metadata;
  }

  public getPrimaryField(): FieldMetadata {
    const primaryFieldEntry = Object.entries(this.schema.properties || {}).find(
      ([, fieldSchema]) => (fieldSchema as ClarifyJSONSchema7).xClarifyPrimary,
    );

    if (!primaryFieldEntry) {
      throw new Error(`Primary field not found in ${this.objectName}`);
    }

    return this.getField(primaryFieldEntry[0]);
  }

  public getPrimaryImageField(): FieldMetadata | undefined {
    const primaryFieldEntry = Object.entries(this.schema.properties || {}).find(
      ([, fieldSchema]) =>
        (fieldSchema as ClarifyJSONSchema7).xClarifyPrimaryImage,
    );

    return primaryFieldEntry ? this.getField(primaryFieldEntry[0]) : undefined;
  }

  public getFields({
    includeRelationships,
    includeHidden,
    includeArchived,
    resolveFields,
  }: {
    includeRelationships?: boolean;
    includeHidden?: boolean;
    includeArchived?: boolean;
    resolveFields?: boolean;
  } = {}): FieldMetadata[] {
    const result = this._getFields({
      includeHidden,
      includeArchived,
      resolveFields,
    }).flatMap((f) => {
      const fields: FieldMetadata[] = [f];

      if (includeRelationships && f.relationship) {
        fields.push(
          ...this.deriveInstance(f.relationship.entity, {
            join: {
              schemaName: this.objectName,
              field: f.name,
            },
          }).getFields(),
        );
      }

      return fields;
    });

    return result.sort(
      (a, b) => this.getFieldMetaDataRank(a) - this.getFieldMetaDataRank(b),
    );
  }

  private _getFields(params: GetFieldsParams = {}): FieldMetadata[] {
    const {
      includeHidden = false,
      includeArchived = false,
      resolveFields = false,
    } = params;
    return Object.keys(this.schema.properties || {}).reduce<FieldMetadata[]>(
      (acc, fieldName) => {
        const field = this.getField(fieldName);

        if (
          (includeHidden || !field.isHidden) &&
          (includeArchived || !field.isArchived)
        ) {
          const _field = resolveFields
            ? this.resolveField(field, params)
            : field;
          acc.push(_field);
        }

        return acc;
      },
      [],
    );
  }

  private resolveField(
    field: FieldMetadata,
    params: GetFieldsParams,
  ): FieldMetadata {
    const schema = this.getAnyOrOneOf(field.schema).find(
      (schema) => schema.$ref,
    );

    if (!schema?.$ref) {
      return field;
    }

    // Limit call depth https://github.com/clarifyhq/clarify/pull/1406#discussion_r1844043248
    const fields = this.deriveInstance(schema?.$ref).getFields({
      ...params,
      resolveFields: false,
    });

    const relatedSchema = this.schemas.get(schema.$ref);

    invariant(relatedSchema, `Schema ${schema.$ref} not found`);

    const properties = Object.entries(relatedSchema?.properties || {}).reduce(
      (acc, [key, value]) => {
        if ((value as ClarifyJSONSchema7).xClarifyHidden) {
          return acc;
        }
        acc[key] = value as JSONSchema7;
        return acc;
      },
      {} as Record<string, JSONSchema7>,
    );

    return {
      ...field,
      fields,
      schema: { ...field.schema, properties },
    };
  }

  public getEssentialFields(): FieldMetadata[] {
    return this.getFields({
      includeHidden: true,
    }).filter((f) => f.schema.xClarifyEssential);
  }

  public getAiColumnFields({
    includeRelationships,
  }: { includeRelationships?: boolean } = {}): FieldMetadata[] {
    return this.getFields({
      includeRelationships,
    }).filter((f) => f.schema.xClarifyAiColumn);
  }

  public getSortFields({
    includeRelationships,
  }: { includeRelationships?: boolean } = {}): FieldMetadata[] {
    return this.getFields({
      includeRelationships,
    }).filter((f) => f.sortedInView);
  }

  public getFieldsPaths({
    includeRelationships,
  }: { includeRelationships?: boolean } = {}): string[] {
    return this.getFields({ includeRelationships }).map((f) => f.path);
  }

  public getRelationshipFields(): FieldMetadata[] {
    return this._getFields({ includeHidden: true }).filter(
      (f) => f.schema.xClarifyRelationship,
    );
  }

  public getImportableRelationships(): FieldMetadata[] {
    return this.getRelationshipFields().filter((f) =>
      // TODO: (ENG-2015) schema driven import fields
      importableEntityRelationshipsMap[this.objectName]?.includes(f.name),
    );
  }

  public getImportableFields(): FieldMetadata[] {
    return this.getFields({
      resolveFields: true,
      // some relationships fields are hidden
      includeHidden: true,
    }).filter((field) => {
      if (field.isReadOnly) {
        return false;
      }

      const isRelationshipField = field.schema.xClarifyRelationship;

      if (isRelationshipField) {
        return importableEntityRelationshipsMap[this.objectName]?.includes(
          field.name,
        );
      }

      if (!field.isHidden && field.fields) {
        field.fields = field.fields.filter((f) => !f.isHidden);
        return field;
      }

      return !field.isHidden;
    });
  }

  public getUniqueConstraint(): FieldMetadata[] {
    return this.getFields().filter((f) => f.schema.xClarifyUnique);
  }

  private getFieldMetaDataRank(f: FieldMetadata): number {
    if (f.schema.xClarifyPresentation?.rank !== undefined) {
      return f.schema.xClarifyPresentation.rank;
    }

    if (f.isPrimary) {
      return PROPERTY_RANK_PRIMARY;
    }

    if (f.relationship || f.isRelationship) {
      // When sorting, we consider a field to be a relationship field if it is the reference to the entity or an attribute field of that entity
      return PROPERTY_RANK_RELATIONSHIP;
    }

    if (f.type === SchemaFieldType.Uuid) {
      return PROPERTY_RANK_UUID;
    }

    return PROPERTY_RANK_DEFAULT;
  }

  private getColumnWidth = (field: FieldMetadata) => {
    if (field.isSocialHandleType) {
      return 150;
    }

    if (field.name === 'title') {
      return 300;
    }

    if (field.schema.format === 'html') {
      return 300;
    }

    switch (field.type) {
      case SchemaFieldType.Company:
        return 200;
      case SchemaFieldType.Markdown:
        return 400;
      case SchemaFieldType.DateTime:
        return 225;
      case SchemaFieldType.Boolean:
        return 50;
      case SchemaFieldType.CollectionOfParticipants:
        return 300;
      default:
        return 200;
    }
  };

  private getFieldSchema(fieldName: string): ClarifyJSONSchema7 {
    const fieldSchema = this.schema.properties?.[fieldName] as JSONSchema7;

    if (!fieldSchema) {
      throw new Error(`Field ${fieldName} not found in schema.properties`);
    }

    return fieldSchema as JSONSchema7 & AvailableXClarifyParams;
  }

  private getDataType(field: FieldMetadata) {
    if (field.enum?.multiselect) {
      return FilterDataType.EnumMultiselect;
    }

    if (field.isEnumType) {
      return FilterDataType.Enum;
    }

    if (field.isCollectionType) {
      return FilterDataType.Collection;
    }

    if (field.isObjectType) {
      return FilterDataType.Record;
    }

    switch (field.type) {
      case SchemaFieldType.DateTime:
      case SchemaFieldType.Date:
        return FilterDataType.Date;

      case SchemaFieldType.Number:
        return FilterDataType.Number;
      case SchemaFieldType.Uuid:
        return FilterDataType.Uuid;
      case SchemaFieldType.Boolean:
        return FilterDataType.Boolean;

      default:
        return FilterDataType.String;
    }
  }

  private normalizeFieldType(
    fieldSchema: ClarifyJSONSchema7,
  ): SchemaFieldType | never {
    const isDateField =
      has(fieldSchema, 'format') &&
      JSON_SCHEMA_DATE_FORMATS.has(fieldSchema.format as any);

    if (JSON_SCHEMA_SOCIAL_HANDLE_TYPES.has(fieldSchema.format as any)) {
      return fieldSchema.format as SchemaFieldType;
    }

    if (fieldSchema.format === SchemaFieldType.Markdown) {
      return SchemaFieldType.Markdown;
    }

    if (fieldSchema.format === SchemaFieldType.Uuid) {
      return SchemaFieldType.Uuid;
    }

    if (isString(fieldSchema.type)) {
      if (isDateField) {
        return fieldSchema.format as SchemaFieldType;
      }

      return fieldSchema.type as SchemaFieldType;
    }

    if (this.hasAnyOrOneOf(fieldSchema)) {
      const schema = this.getAnyOrOneOf(fieldSchema).find(
        (schema) => schema.$ref,
      );

      if (!schema) {
        this.thrownSchemaTypeError(fieldSchema);
      }

      return schema!.$ref as SchemaFieldType;
    }

    if (Array.isArray(fieldSchema.type)) {
      if (isDateField) {
        return fieldSchema.format as SchemaFieldType;
      }

      const type = fieldSchema.type.find(
        (type) => type !== SchemaFieldType.Null,
      );

      if (!type) {
        this.thrownSchemaTypeError(fieldSchema);
      }

      return type as SchemaFieldType;
    }

    this.thrownSchemaTypeError(fieldSchema);
  }

  private hasAnyOrOneOf(fieldSchema: JSONSchema7): boolean {
    return has(fieldSchema, 'anyOf') || has(fieldSchema, 'oneOf');
  }

  private getAnyOrOneOf(fieldSchema: JSONSchema7): JSONSchema7[] {
    return (fieldSchema.anyOf || fieldSchema.oneOf || []) as JSONSchema7[];
  }

  private thrownSchemaTypeError(fieldSchema: JSONSchema7): never {
    throw new Error(
      `Can't extract type from schema: ${JSON.stringify(fieldSchema)}`,
    );
  }

  private getEnumData(field: FieldMetadata): FieldMetadata['enum'] {
    if (Array.isArray(field.schema.enum)) {
      return {
        multiselect: false,
        type: field.type,
        values: field.schema.enum as string[],
        metadata: field.schema.xClarifyEnumMetadata,
      };
    }

    const fieldSchema = has(field.schema, 'properties.items.items.enum')
      ? field.schema
      : this.deriveInstance(field.type).schema;
    const enumValues = get(fieldSchema, 'properties.items.items.enum') as
      | string[]
      | undefined;
    const enumMetaData = get(
      fieldSchema,
      'properties.items.items.xClarifyEnumMetadata',
    ) as xClarifyEnumMetadata | undefined;
    const enumType = get(
      fieldSchema,
      'properties.items.items.type',
    ) as unknown as SchemaFieldType;

    if (enumValues === undefined) {
      throw new Error('Enum values not found in schema');
    }

    return {
      multiselect: true,
      // we're making an assumption that enum types are always plain types (string, number, etc.)
      type: enumType,
      values: enumValues,
      metadata: enumMetaData,
    };
  }
}

export const schemasArrayToMap = (
  schemas: JSONSchema7[],
): Map<string, JSONSchema7> =>
  schemas.reduce((acc, schema) => {
    if (!schema.$id) {
      return acc;
    }
    acc.set(schema.$id, schema);
    return acc;
  }, new Map<string, JSONSchema7>());
