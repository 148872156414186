/**
 * Events that power the activity feed and are stored in the database
 */
export enum CdcEventType {
  ClarifyCreate = 'clarify:create',
  ClarifyUpdate = 'clarify:update',
  ClarifyDelete = 'clarify:delete',
  ClarifyAddToList = 'clarify:add-to-list',
  ClarifyRemoveFromList = 'clarify:remove-from-list',
  ClarifySetRelationship = 'clarify:set-relationship',
  ClarifyUnsetRelationship = 'clarify:unset-relationship',

  // Not stored in the database, created during feed evaluation
  ClarifyMeeting = 'clarify:meeting',
}

/**
 * Events that represent messages used for in-process communication
 * and event-driven programming
 */
export enum SignalEventType {
  ClarifyHistoricalIngestionCompleted = 'clarify:historical-ingestion-completed',
}

export type EventType = CdcEventType | SignalEventType;
