import { KnownEntity } from '../constants';

export const PROPERTY_RANK_PRIMARY = Number.MIN_SAFE_INTEGER;
export const PROPERTY_RANK_UUID = Number.MAX_SAFE_INTEGER;
export const PROPERTY_RANK_RELATIONSHIP = Number.MAX_SAFE_INTEGER - 1;
export const PROPERTY_RANK_DEFAULT = 500;
export const CUSTOM_PROPERTY_RANK = 1000;
export const importableEntityRelationshipsMap: Record<string, string[]> =
  Object.freeze({
    [KnownEntity.Company]: [] as string[],
    [KnownEntity.Person]: ['company_id'],
    [KnownEntity.Deal]: ['people', 'companies', 'owner_id'],
  });
