import { JSONSchema7 } from 'json-schema';
import has from 'lodash/has';

import { invariant } from '@repo/shared';

import { CUSTOM_PROPERTY_RANK } from '../JsonSchemaHelper/constants';
import {
  ClarifyJSONSchema7,
  xClarifyEnumMetadata,
} from '../JsonSchemaHelper/types';

export function isMultiselectEnum(schema: JSONSchema7) {
  return Boolean(has(schema, 'properties.items.items.enum'));
}

export type EnumSchemaDiffItem =
  | {
      path: string;
      op: 'add';
      enumType: 'multi' | 'single';
      val: string;
    }
  | {
      path: string;
      op: 'rename';
      enumType: 'multi' | 'single';
      oldVal: string;
      val: string;
    }
  | {
      path: string;
      op: 'remove';
      enumType: 'multi' | 'single';
      oldVal: string;
    };

const getMeta = (
  schema: ClarifyJSONSchema7,
  slug: string,
): xClarifyEnumMetadata | undefined => {
  const prop = schema.properties?.[slug] as ClarifyJSONSchema7;

  if (typeof prop === 'boolean') {
    throw new Error('Invalid schema');
  }

  return prop.enum
    ? prop.xClarifyEnumMetadata
    : (prop.properties?.items as any)?.items?.xClarifyEnumMetadata;
};

export function getSchemaEnumsDiff(
  original: JSONSchema7,
  next: JSONSchema7,
): Array<EnumSchemaDiffItem> {
  const enumSlugItems = Object.entries(original.properties ?? {}).reduce<
    Pick<EnumSchemaDiffItem, 'path' | 'enumType'>[]
  >((acc, [path, _property]) => {
    const property = _property as ClarifyJSONSchema7;

    const isMultiselect = isMultiselectEnum(property);
    const isEnum = isMultiselect || property.enum;

    // we do not process built-in enums
    if (isEnum && !property.xClarifyProtected) {
      acc.push({ path, enumType: isMultiselect ? 'multi' : 'single' });
      return acc;
    }
    return acc;
  }, []);

  if (enumSlugItems.length === 0) {
    return [];
  }

  const changeList: EnumSchemaDiffItem[] = [];

  const mapOptionsById = (meta: xClarifyEnumMetadata) => {
    return Object.entries(meta).reduce<
      Map<string, xClarifyEnumMetadata[string] & { title: string }>
    >((acc, [title, meta]) => {
      invariant(meta.id, 'Enum option id is missing');
      return acc.set(meta.id, { ...meta, title });
    }, new Map());
  };

  enumSlugItems.forEach(({ path, enumType }) => {
    const originalMeta = getMeta(original as ClarifyJSONSchema7, path);
    invariant(originalMeta, 'Original meta not found');
    const nextMeta = getMeta(next as ClarifyJSONSchema7, path);
    invariant(nextMeta, 'Next meta not found');

    const originalOptionsById = mapOptionsById(originalMeta);
    const nextOptionsById = mapOptionsById(nextMeta);

    const originalIds = Array.from(originalOptionsById.keys());
    const nextIds = Array.from(nextOptionsById.keys());

    originalIds.map((id) => {
      const originalOption = originalOptionsById.get(id);
      invariant(
        originalOption,
        "Original option not found by id - it's a code error",
      );
      const nextOption = nextOptionsById.get(id);

      if (!nextOption) {
        changeList.push({
          path,
          op: 'remove',
          oldVal: originalOption.title,
          enumType,
        });
        return;
      }

      if (originalOption.title !== nextOption.title) {
        changeList.push({
          path,
          op: 'rename',
          oldVal: originalOption.title,
          val: nextOption.title,
          enumType,
        });
      }
    });

    nextIds.map((id) => {
      const nextOption = nextOptionsById.get(id);
      invariant(nextOption, "Next option not found by id - it's a code error");
      const originalOption = originalOptionsById.get(id);

      if (!originalOption) {
        changeList.push({
          path,
          op: 'add',
          val: nextOption.title,
          enumType,
        });
      }
    });
  });

  return changeList;
}

type EnumOption = {
  id: string;
  value: string;
  rank: number;
  color?: string | undefined;
};

export function constructXClarifyEnumMetadata(
  options: EnumOption[],
): Record<string, xClarifyEnumMetadata> {
  return options.reduce((acc, option) => {
    const enumMeta: xClarifyEnumMetadata = {
      [option.value]: {
        id: option.id,
        rank: option.rank,
        color: option.color,
      },
    };

    return { ...acc, ...enumMeta };
  }, {});
}

type CreateEnumParams = {
  title: string;
  rank: number;
  options: EnumOption[];
};

export const createEnumSchema = ({
  title,
  rank,
  options,
}: CreateEnumParams) => {
  const enumValues = options.map((option) => option.value);

  return {
    title,
    type: ['string', 'null'],
    enum: [null, ...enumValues],
    xClarifyEnumMetadata: constructXClarifyEnumMetadata(options),
    xClarifyPresentation: { rank },
  };
};

export const createEnumMultiSchema = ({
  title,
  rank,
  options,
}: CreateEnumParams) => {
  const enumValues = options.map((option) => option.value);

  return {
    title,
    type: ['object', 'null'],
    required: ['items'],
    additionalProperties: false,
    xClarifyPresentation: { rank },
    properties: {
      items: {
        type: 'array',
        items: {
          type: 'string',
          enum: enumValues,
          xClarifyEnumMetadata: constructXClarifyEnumMetadata(options),
        },
      },
    },
  };
};

export const getCustomFieldMaxRank = (schema: ClarifyJSONSchema7) => {
  const customPropertiesRanks = Object.entries(schema.properties ?? {}).reduce<
    number[]
  >((acc, [, property]) => {
    const prop = property as ClarifyJSONSchema7;

    if (prop.xClarifyProtected) {
      return acc;
    }

    const rank = prop.xClarifyPresentation?.rank ?? CUSTOM_PROPERTY_RANK;

    return [...acc, rank];
  }, []);

  const maxRank =
    customPropertiesRanks.length === 0
      ? CUSTOM_PROPERTY_RANK
      : Math.max(...customPropertiesRanks);

  return maxRank;
};

export const slugifyTitle = (title: string) => {
  return title
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '_') // Replace non-alphanumeric characters with '_'
    .replace(/^_+|_+$/g, ''); // Remove leading or trailing underscores
};

export const getSchemaPropertySlugs = (schema: JSONSchema7) => {
  const { properties = {} } = schema;

  const result = new Set<string>();

  Object.keys(properties).forEach((propName) => {
    result.add(propName);
  });

  return result;
};

export const getCustomFieldSlug = (
  originalSlug: string,
  schema: JSONSchema7,
) => {
  const slugs = getSchemaPropertySlugs(schema);
  let slug = originalSlug;
  let count = 2;

  while (slugs.has(slug)) {
    slug = `${originalSlug}_${count}`;
    count++;
  }

  return slug;
};
