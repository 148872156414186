import { ClarifyObject, KnownEntity } from '@repo/shared';

const knownEntityPlural = {
  [KnownEntity.Person]: 'People',
  [KnownEntity.Company]: 'Companies',
  [KnownEntity.Deal]: 'Deals',
  [KnownEntity.Meeting]: 'Meetings',
  [KnownEntity.MeetingRecording]: 'Meeting Recordings',
  [KnownEntity.Message]: 'Messages',
  [KnownEntity.User]: 'Users',
  [ClarifyObject.Note]: 'Notes',
};

export const constructKnownEntityPlural = (objectName: KnownEntity) => {
  return knownEntityPlural[objectName];
};
