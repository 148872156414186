import { SchemaFieldType } from '../JsonSchemaHelper/types';

export const extractLinkedInHandle = (url: string) => {
  const groups = url.match(/(linkedin\.com\/)?(in|company)\/([^/]+)/i);
  return groups?.[3] ?? null;
};

export const extractAngelListHandle = (url: string) => {
  const groups = url.match(/(angellist|wellfound)\.com\/(u|company)\/([^/]+)/i);
  return groups?.[3] ?? null;
};

export const extractTwitterHandle = (url: string) => {
  const groups = url.match(/(twitter|x)\.com\/([^/]+)/i);
  return groups?.[2] ?? null;
};

export const extractFacebookHandle = (url: string) => {
  const groups = url.match(/facebook\.com\/([^/]+)/i);
  return groups?.[1] ?? null;
};

export const extractInstagramHandle = (url: string) => {
  const groups = url.match(/instagram\.com\/([^/]+)/i);
  return groups?.[1] ?? null;
};

export const constructLinkedInPersonUrl = (handle?: string) => {
  return handle ? `https://www.linkedin.com/in/${handle}` : null;
};

export const constructLinkedInCompanyUrl = (handle?: string) => {
  return handle ? `https://www.linkedin.com/company/${handle}` : null;
};

export const constructAngelListPersonUrl = (handle?: string) => {
  return handle ? `https://wellfound.com/u/${handle}` : null;
};

export const constructAngelListCompanyUrl = (handle?: string) => {
  return handle ? `https://wellfound.com/company/${handle}` : null;
};

export const constructTwitterUrl = (handle?: string) => {
  return handle ? `https://x.com/${handle}` : null;
};

export const constructFacebookUrl = (handle?: string) => {
  return handle ? `https://facebook.com/${handle}` : null;
};

export const constructInstagramUrl = (handle?: string) => {
  return handle ? `https://instagram.com/${handle}` : null;
};

export const constructSocialLink = (
  handle: string,
  type: SchemaFieldType,
  { isCompany = false }: { isCompany?: boolean } = {},
) => {
  switch (type) {
    case SchemaFieldType.LinkedInHandle:
      return isCompany
        ? constructLinkedInCompanyUrl(handle)
        : constructLinkedInPersonUrl(handle);
    case SchemaFieldType.AngelListHandle:
      return isCompany
        ? constructAngelListCompanyUrl(handle)
        : constructAngelListPersonUrl(handle);
    case SchemaFieldType.TwitterHandle:
      return constructTwitterUrl(handle);
    case SchemaFieldType.FacebookHandle:
      return constructFacebookUrl(handle);
    case SchemaFieldType.InstagramHandle:
      return constructInstagramUrl(handle);
    default:
      return null;
  }
};

export const extractSocialHandle = (url: string, type: SchemaFieldType) => {
  switch (type) {
    case SchemaFieldType.LinkedInHandle:
      return extractLinkedInHandle(url);
    case SchemaFieldType.AngelListHandle:
      return extractAngelListHandle(url);
    case SchemaFieldType.TwitterHandle:
      return extractTwitterHandle(url);
    case SchemaFieldType.FacebookHandle:
      return extractFacebookHandle(url);
    case SchemaFieldType.InstagramHandle:
      return extractInstagramHandle(url);
    default:
      return null;
  }
};
