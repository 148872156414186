const prefix: string = 'Invariant failed';

// This is a copy of https://github.com/alexreardon/tiny-invariant/blob/master/src/tiny-invariant.ts
// but our version retains the message in production builds
export function invariant(
  condition: any,
  message?: string | (() => string),
): asserts condition {
  if (condition) {
    return;
  }

  const provided = typeof message === 'function' ? message() : message;

  const value = provided ? `${prefix}: ${provided}` : prefix;

  throw new Error(value);
}
