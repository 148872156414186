import { NIL as NIL_UUID } from 'uuid';

export const EMPTY_UUID = NIL_UUID;

// Only add new items to this enum if they have a schema and can use the standard records service
export enum KnownEntity {
  Company = 'company',
  Person = 'person',
  Deal = 'deal',
  Meeting = 'meeting',
  MeetingRecording = 'meeting_recording',
  Message = 'message',
  User = 'user',
}

// Only add system objects (i.e. objects that don't have a schema) to this enum
export enum ClarifyObject {
  List = 'list',
  ListView = 'list-view',
  Comment = 'comment',
  Note = 'note',
}

export enum KnownRelationship {
  Companies = 'companies',
  People = 'people',
  Deals = 'deals',
  Notes = 'notes',
  Meetings = 'meetings',
  CompanyId = 'company_id',
}

const DEFAULT_RELATIONSHIPS = {
  [KnownEntity.Company]: KnownRelationship.Companies,
  [KnownEntity.Person]: KnownRelationship.People,
  [KnownEntity.Deal]: KnownRelationship.Deals,
  [ClarifyObject.Note]: KnownRelationship.Notes,
  [KnownEntity.Meeting]: KnownRelationship.Meetings,
};

type RELATIONSHIP_ENTITIES =
  | KnownEntity.Company
  | KnownEntity.Person
  | KnownEntity.Deal
  | KnownEntity.Meeting
  | ClarifyObject.Note;

export const KnownEntityToRelationship: Record<
  RELATIONSHIP_ENTITIES,
  Record<RELATIONSHIP_ENTITIES, KnownRelationship>
> = Object.freeze({
  [KnownEntity.Company]: DEFAULT_RELATIONSHIPS,
  [KnownEntity.Person]: {
    ...DEFAULT_RELATIONSHIPS,
    [KnownEntity.Company]: KnownRelationship.CompanyId,
  },
  [KnownEntity.Deal]: DEFAULT_RELATIONSHIPS,
  [ClarifyObject.Note]: DEFAULT_RELATIONSHIPS,
  [KnownEntity.Meeting]: DEFAULT_RELATIONSHIPS,
});

export type KnownObject =
  | KnownEntity.Company
  | KnownEntity.Person
  | KnownEntity.Deal
  | KnownEntity.Meeting;

export enum RelationshipKind {
  ManyToOne = 'many-to-one',
  ManyToMany = 'many-to-many',
  OneToMany = 'one-to-many',
  OneToOne = 'one-to-one',
}

export enum AsyncTaskType {
  GoogleSyncEmails = 'google_sync_emails',
  GoogleSyncCalendar = 'google_sync_calendar',
  SchemaModification = 'schema_modification',
}

export const clarifyDomain = 'getclarify.ai';
export const clarifySlugs = new Set(['clarify', 'test']);

export const NO_NAME = 'No name';
export const NO_DEAL_NAME = 'Unnamed deal';

export const importableEntities = new Set<string>([
  KnownEntity.Company,
  KnownEntity.Person,
  KnownEntity.Deal,
]);

export enum EventSource {
  ClarifyGoogleWorkspace = 'clarify:google-workspace',
  ClarifyGoogleWorkspaceHistorical = 'clarify:google-workspace-historical',
}
