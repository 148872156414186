interface PersonName {
  first_name: string;
  last_name: string;
}

/**
 * Extracts the first and last name using the last space in the string. This will work "good enough" for our use case,
 * but it's far from perfect (see linked article for more information)
 * @see: https://www.kalzumeus.com/2010/06/17/falsehoods-programmers-believe-about-names/
 * @param input
 */
export const extractPersonName = (input: string): PersonName | null => {
  // Sometimes the email is passed in, so we'll skip this
  if (input.includes('@')) {
    return null;
  }

  const normalized = input.trim();

  // Some names are formatted as "Last, First" so we'll split on the comma
  const commaDelimited = normalized.split(',');
  if (commaDelimited.length === 2) {
    return {
      first_name: commaDelimited[1]?.trim() ?? '',
      last_name: commaDelimited[0]?.trim() ?? '',
    };
  }

  const lastSpaceIndex = normalized.lastIndexOf(' ');
  // If no space is found, we'll assume the entire string is the first name
  if (lastSpaceIndex === -1) {
    return {
      first_name: normalized,
      last_name: '',
    };
  }

  // Otherwise, we'll split on the last space
  return {
    first_name: normalized.slice(0, lastSpaceIndex).trim() ?? '',
    last_name: normalized.slice(lastSpaceIndex + 1).trim() ?? '',
  };
};

export const constructInitials = (_name: string | PersonName) => {
  let name: string | PersonName;

  if (typeof _name === 'string') {
    name = extractPersonName(_name) ?? { first_name: '', last_name: '' };
  } else {
    name = _name;
  }

  const [firstNameInitial, lastNameInitial] = [
    name?.first_name?.[0] || '',
    name?.last_name?.[0] || '',
  ];

  return firstNameInitial.toUpperCase() + lastNameInitial.toUpperCase();
};

export function constructFullName(
  params: null | Partial<PersonName> | string = {},
) {
  if (typeof params === 'string') {
    return params;
  }

  const { first_name, last_name } = params ?? {};
  return [first_name, last_name].filter(Boolean).join(' ').trim();
}
