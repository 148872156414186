import { isCommentEvent } from '../utils/event.utils';

export const KEY_DELIMITER = '::';

export interface EventRowLike {
  _id: string;
  _created_at: Date;
  type: string;
  data: Record<string, any>;
}

export function getAggKey(eventRow: EventRowLike): string {
  // Group all events by actor id if it exists
  const actorId = eventRow.data.actor?._id;
  let suffix = actorId ? `${KEY_DELIMITER}${actorId}` : '';

  if (isCommentEvent(eventRow.data)) {
    suffix = `${KEY_DELIMITER}${eventRow._id}`;
  }

  return (
    // events are grouped by hour and action type (e.g. 475968::clarify:create)
    Math.floor(eventRow._created_at.getTime() / (1000 * 60 * 60)) +
    KEY_DELIMITER +
    eventRow.type +
    suffix
  );
}
