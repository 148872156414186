import { ClarifyObject } from '../constants';

export const isCommentEvent = (
  event: Record<string, any> | null | undefined,
): boolean => {
  return event?.object?.entity === ClarifyObject.Comment;
};

export const getFirstAggregationEvent = (
  activityItem: Record<string, any>,
): Record<string, any> | null => {
  return activityItem.attributes.data[0];
};
