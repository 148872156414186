import type { JSONSchema7 } from 'json-schema';

import type { MetaSchema } from '@repo/shared/src/schemas/meta';

import { FilterDataType } from '../view-to-sql/constants';

type StartsWithXClarify<T> = {
  [K in keyof T as K extends `xClarify${string}` ? K : never]: T[K];
};

export type AvailableXClarifyKeys = keyof StartsWithXClarify<
  MetaSchema['properties']
>;

export type AvailableXClarifyParams = Record<
  Exclude<
    AvailableXClarifyKeys,
    'xClarifyPresentation' | 'xClarifyEnumMetadata'
  >,
  any
> & {
  xClarifyPresentation?: Record<
    keyof MetaSchema['properties']['xClarifyPresentation']['properties'],
    any
  >;
  xClarifyEnumMetadata?: Record<
    string,
    Record<
      keyof MetaSchema['properties']['xClarifyEnumMetadata']['additionalProperties']['oneOf'][1]['properties'],
      any
    >
  >;
};

export type ClarifyJSONSchema7 = JSONSchema7 & Partial<AvailableXClarifyParams>;

// standard types match JSONSchema7TypeName + custom ones
export enum SchemaFieldType {
  String = 'string',
  Number = 'number',
  Integer = 'integer',
  Boolean = 'boolean',
  Object = 'object',
  Array = 'array',
  Null = 'null',
  Html = 'html',

  // custom types
  Date = 'date',
  DateTime = 'date-time',
  Time = 'time',
  Duration = 'duration',
  Markdown = 'markdown',
  Uuid = 'uuid',
  CollectionOfIds = 'https://getclarify.ai/schemas/core/collectionOfIds',
  CollectionOfStrings = 'https://getclarify.ai/schemas/core/collectionOfStrings',
  CollectionOfNumbers = 'https://getclarify.ai/schemas/core/collectionOfNumbers',
  CollectionOfHostnames = 'https://getclarify.ai/schemas/core/collectionOfHostnames',
  CollectionOfEmails = 'https://getclarify.ai/schemas/core/collectionOfEmails',
  CollectionOfParticipants = 'https://getclarify.ai/schemas/core/collectionOfParticipants',
  Location = 'https://getclarify.ai/schemas/core/location',
  PersonName = 'https://getclarify.ai/schemas/core/personName',
  EmailRecipient = 'https://getclarify.ai/schemas/core/emailRecipient',
  EmailMetadata = 'https://getclarify.ai/schemas/core/emailMetadata',
  User = 'https://getclarify.ai/schemas/core/user',
  Company = 'https://getclarify.ai/schemas/core/company',
  Person = 'https://getclarify.ai/schemas/core/person',
  Message = 'https://getclarify.ai/schemas/core/message',
  MeetingParticipant = 'https://getclarify.ai/schemas/core/meetingParticipant',
  Meeting = 'https://getclarify.ai/schemas/core/meeting',
  Categories = 'https://getclarify.ai/schemas/core/categories',
  AngelListHandle = 'angel-list-id',
  FacebookHandle = 'facebook-id',
  InstagramHandle = 'instagram-id',
  LinkedInHandle = 'linkedin-id',
  TwitterHandle = 'twitter-id',
}

// https://json-schema.org/draft/2020-12/draft-bhutton-json-schema-validation-00#rfc.section.7.3.1
export const JSON_SCHEMA_DATE_FORMATS = new Set([
  SchemaFieldType.DateTime,
  SchemaFieldType.Date,
  SchemaFieldType.Time,
  SchemaFieldType.Duration,
]);

export const JSON_SCHEMA_COLLECTION_TYPES = new Set([
  SchemaFieldType.Array,
  SchemaFieldType.CollectionOfIds,
  SchemaFieldType.CollectionOfStrings,
  SchemaFieldType.CollectionOfNumbers,
  SchemaFieldType.CollectionOfHostnames,
  SchemaFieldType.CollectionOfEmails,
  SchemaFieldType.CollectionOfParticipants,
]);

export const JSON_SCHEMA_PRIMITIVE_TYPES = new Set([
  SchemaFieldType.String,
  SchemaFieldType.Number,
  SchemaFieldType.Integer,
  SchemaFieldType.Boolean,
  SchemaFieldType.Null,
]);

export const JSON_SCHEMA_SOCIAL_HANDLE_TYPES = new Set([
  SchemaFieldType.AngelListHandle,
  SchemaFieldType.FacebookHandle,
  SchemaFieldType.InstagramHandle,
  SchemaFieldType.LinkedInHandle,
  SchemaFieldType.TwitterHandle,
]);

export const JSON_SCHEMA_ENUM_TYPES = new Set([SchemaFieldType.Categories]);

export type RelationshipKeys =
  keyof MetaSchema['properties']['xClarifyRelationship']['properties'];

export type Relationship = Record<RelationshipKeys, any>;

type DotSeparatedString = string;

/**
 * Metadata about how this schema was joined to the schema it was reached from.
 *
 * For e.g. when including company fields on a person the values on company fields could be { schemaName: 'person', field: 'company_id' }.
 */
export type FieldMetadataJoinInfo = { schemaName: string; field: string };

export type FieldMetadata = {
  type: SchemaFieldType;
  schema: ClarifyJSONSchema7;
  isPrimitiveType: boolean;
  isCollectionType: boolean;
  isDateType: boolean;
  isSocialHandleType: boolean;
  isObjectType: boolean;
  isPrimary: boolean;
  isReadOnly: boolean;
  isHidden: boolean;
  isArchived: boolean;
  isRestricted: boolean;
  isCompany: boolean;
  isProtected: boolean;
  isSecure: boolean;
  title: string;
  name: string;
  path: DotSeparatedString;
  schemaName: string;
  dataType: FilterDataType;
  isEnumType: boolean;
  enum?: {
    type: SchemaFieldType;
    multiselect: boolean;
    values: string[];
    metadata?: xClarifyEnumMetadata;
  };
  width: number;
  isCollectionTypeLike: boolean;
  isPrimaryImage: boolean;
  isViewDefault: boolean;
  sortedInView?: 'DESC' | 'ASC'; // TODO remove in favour of presentation.sortedInView
  presentation?: {
    rank?: number;
    segmentedByInBoard?: boolean;
  };
  fields?: FieldMetadata[];
  /**
   * If this field is a relationship to another entity, information about that relationship
   */
  relationship?: Relationship;
} & (
  | {
      /**
       * True when this is a field on a related entity (only can be true when includeRelationship is true)
       */
      isRelationship: false;
    }
  | {
      /**
       * True when this is a field on a related entity (only can be true when includeRelationship is true)
       */
      isRelationship: true;
      join: FieldMetadataJoinInfo;
    }
);

export type xClarifyEnumMetadata = Record<
  string,
  {
    description?: string;
    deprecationMessage?: string;
    rank?: number;
    color?: string;
    id?: string;
  }
>;
