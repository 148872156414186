import { mapStringArrayToOptions } from '../utils/mapStringArrayToOptions';

export enum FilterDataType {
  String = 'String',
  Uuid = 'Uuid',
  Record = 'Record',
  Enum = 'Enum',
  EnumMultiselect = 'EnumMultiselect',
  Number = 'Number',
  Date = 'Date',
  Boolean = 'Boolean',
  Collection = 'Collection',
}

export enum FilterStringOption {
  Is = 'Is',
  IsNot = 'Is not',
  Contains = 'Contains',
  DoesNotContain = 'Does not contain',
  StartsWith = 'Starts with',
  EndsWith = 'Ends with',
  IsEmpty = 'Is empty',
  IsNotEmpty = 'Is not empty',
}

export const FilterStringOptionInversionMap = Object.freeze({
  [FilterStringOption.Is]: FilterStringOption.IsNot,
  [FilterStringOption.IsNot]: FilterStringOption.Is,
  [FilterStringOption.Contains]: FilterStringOption.DoesNotContain,
  [FilterStringOption.DoesNotContain]: FilterStringOption.Contains,
  [FilterStringOption.IsEmpty]: FilterStringOption.IsNotEmpty,
  [FilterStringOption.IsNotEmpty]: FilterStringOption.IsEmpty,
});

export const StringOperatorMap: Record<string, any> = Object.freeze({
  '=': FilterStringOption.Is,
  '<>': FilterStringOption.IsNot,
});

export enum FilterUuidOption {
  Is = 'Is',
  IsNot = 'Is not',
  IsEmpty = 'Is empty',
  IsNotEmpty = 'Is not empty',
}

export const UuidOperatorMap: Record<string, any> = Object.freeze({
  '=': FilterUuidOption.Is,
  '<>': FilterUuidOption.IsNot,
});

export const FilterUuidOptionInversionMap = Object.freeze({
  [FilterUuidOption.Is]: FilterUuidOption.IsNot,
  [FilterUuidOption.IsNot]: FilterUuidOption.Is,
  [FilterUuidOption.IsEmpty]: FilterUuidOption.IsNotEmpty,
  [FilterUuidOption.IsNotEmpty]: FilterUuidOption.IsEmpty,
});

export enum FilterRecordOptions {
  Contains = 'Contains',
  DoesNotContain = 'Does not contain',
}

export const FilterRecordOptionInversionMap = Object.freeze({
  [FilterRecordOptions.Contains]: FilterRecordOptions.DoesNotContain,
  [FilterRecordOptions.DoesNotContain]: FilterRecordOptions.Contains,
});

export enum FilterEnumOption {
  Is = 'Is',
  IsNot = 'Is not',
  IsEmpty = 'Is empty',
  IsNotEmpty = 'Is not empty',
  OneOf = 'One of',
  NotOneOf = 'Not one of',
}

export const FilterEnumOptionInversionMap = Object.freeze({
  [FilterEnumOption.Is]: FilterEnumOption.IsNot,
  [FilterEnumOption.IsNot]: FilterEnumOption.Is,
  [FilterEnumOption.IsEmpty]: FilterEnumOption.IsNotEmpty,
  [FilterEnumOption.IsNotEmpty]: FilterEnumOption.IsEmpty,
  [FilterEnumOption.OneOf]: FilterEnumOption.NotOneOf,
  [FilterEnumOption.NotOneOf]: FilterEnumOption.OneOf,
});

export const EnumOperatorMap: Record<string, any> = Object.freeze({
  '=': FilterStringOption.Is,
  '<>': FilterStringOption.IsNot,
  IN: FilterEnumOption.OneOf,
});

export enum FilterEnumMultiselectOption {
  Is = 'Is', // For backwards compatibility, should not be present in UI
  IsNot = 'Is not', // For backwards compatibility, should not be present in UI
  Contains = 'Contains',
  DoesNotContain = 'Does not contain',
  IsEmpty = 'Is empty',
  IsNotEmpty = 'Is not empty',
}

export const EnumMultiselectOperatorMap: Record<string, any> = Object.freeze({
  '?|': FilterStringOption.Contains,
  '?': FilterStringOption.Contains, // we handle normalization to ?|
  '=': FilterEnumMultiselectOption.Is,
  '<>': FilterEnumMultiselectOption.IsNot,
});

export const FilterEnumMultiselectOptionInversionMap = Object.freeze({
  [FilterEnumMultiselectOption.Is]: FilterEnumMultiselectOption.IsNot,
  [FilterEnumMultiselectOption.IsNot]: FilterEnumMultiselectOption.Is,
  [FilterEnumMultiselectOption.Contains]:
    FilterEnumMultiselectOption.DoesNotContain,
  [FilterEnumMultiselectOption.DoesNotContain]:
    FilterEnumMultiselectOption.Contains,
  [FilterEnumMultiselectOption.IsEmpty]: FilterEnumMultiselectOption.IsNotEmpty,
  [FilterEnumMultiselectOption.IsNotEmpty]: FilterEnumMultiselectOption.IsEmpty,
});

export enum FilterNumberOption {
  Is = 'Is',
  IsNot = 'Is not',
  GreaterThan = 'Greater than',
  GreaterThanOrEqual = 'Greater than or equal',
  LessThan = 'Less than',
  LessThanOrEqual = 'Less than or equal',
  IsEmpty = 'Is empty',
  IsNotEmpty = 'Is not empty',
}

export const NumberOperatorMap: Record<string, FilterNumberOption> = {
  '=': FilterNumberOption.Is,
  '<>': FilterNumberOption.IsNot,
  '>': FilterNumberOption.GreaterThan,
  '>=': FilterNumberOption.GreaterThanOrEqual,
  '<': FilterNumberOption.LessThan,
  '<=': FilterNumberOption.LessThanOrEqual,
};

export const FilterNumberOptionInversionMap = Object.freeze({
  [FilterNumberOption.Is]: FilterNumberOption.IsNot,
  [FilterNumberOption.IsNot]: FilterNumberOption.Is,
  [FilterNumberOption.GreaterThan]: FilterNumberOption.LessThanOrEqual,
  [FilterNumberOption.GreaterThanOrEqual]: FilterNumberOption.LessThan,
  [FilterNumberOption.LessThan]: FilterNumberOption.GreaterThanOrEqual,
  [FilterNumberOption.LessThanOrEqual]: FilterNumberOption.GreaterThan,
  [FilterNumberOption.IsEmpty]: FilterNumberOption.IsNotEmpty,
  [FilterNumberOption.IsNotEmpty]: FilterNumberOption.IsEmpty,
});

export enum FilterDateOption {
  Is = 'Is',
  IsNot = 'Is not',
  IsBefore = 'Is before',
  IsOnOrBefore = 'Is on or before',
  IsAfter = 'Is after',
  IsOnOrAfter = 'Is on or after',
  IsEmpty = 'Is empty',
  IsNotEmpty = 'Is not empty',
}

export const FilterDateOptionInversionMap = Object.freeze({
  [FilterDateOption.Is]: FilterDateOption.IsNot,
  [FilterDateOption.IsNot]: FilterDateOption.Is,
  [FilterDateOption.IsBefore]: FilterDateOption.IsOnOrAfter,
  [FilterDateOption.IsOnOrBefore]: FilterDateOption.IsAfter,
  [FilterDateOption.IsAfter]: FilterDateOption.IsOnOrBefore,
  [FilterDateOption.IsOnOrAfter]: FilterDateOption.IsBefore,
  [FilterDateOption.IsEmpty]: FilterDateOption.IsNotEmpty,
  [FilterDateOption.IsNotEmpty]: FilterDateOption.IsEmpty,
});

export const DateOperatorMap: Record<string, any> = Object.freeze({
  '>': FilterDateOption.IsAfter,
  '>=': FilterDateOption.IsOnOrAfter,
  '<': FilterDateOption.IsBefore,
  '<=': FilterDateOption.IsOnOrBefore,
  '=': FilterDateOption.Is,
  '!=': FilterDateOption.IsNot,
});

export enum FilterBooleanOption {
  Is = 'Is',
  IsNot = 'Is not',
  IsEmpty = 'Is empty',
  IsNotEmpty = 'Is not empty',
}

export const FilterBooleanOptionInversionMap = Object.freeze({
  [FilterBooleanOption.Is]: FilterBooleanOption.IsNot,
  [FilterBooleanOption.IsNot]: FilterBooleanOption.Is,
  [FilterBooleanOption.IsEmpty]: FilterBooleanOption.IsNotEmpty,
  [FilterBooleanOption.IsNotEmpty]: FilterBooleanOption.IsEmpty,
});

export const BooleanOperatorMap: Record<string, any> = Object.freeze({
  '=': FilterStringOption.Is,
  '<>': FilterStringOption.IsNot,
});

export enum FilterCollectionOption {
  Contains = 'Contains',
  DoesNotContain = 'Does not contain',
  IsEmpty = 'Is empty',
  IsNotEmpty = 'Is not empty',
}

export const FilterCollectionOptionInversionMap = Object.freeze({
  [FilterCollectionOption.Contains]: FilterCollectionOption.DoesNotContain,
  [FilterCollectionOption.DoesNotContain]: FilterCollectionOption.Contains,
  [FilterCollectionOption.IsEmpty]: FilterCollectionOption.IsNotEmpty,
  [FilterCollectionOption.IsNotEmpty]: FilterCollectionOption.IsEmpty,
});

export const FilterDataTypeToOptionInversionMap: Readonly<
  Record<string, Record<string, string>>
> = Object.freeze({
  [FilterDataType.String]: FilterStringOptionInversionMap,
  [FilterDataType.Uuid]: FilterUuidOptionInversionMap,
  [FilterDataType.Enum]: FilterEnumOptionInversionMap,
  [FilterDataType.EnumMultiselect]: FilterEnumMultiselectOptionInversionMap,
  [FilterDataType.Number]: FilterNumberOptionInversionMap,
  [FilterDataType.Date]: FilterDateOptionInversionMap,
  [FilterDataType.Boolean]: FilterBooleanOptionInversionMap,
  [FilterDataType.Record]: FilterRecordOptionInversionMap,
  [FilterDataType.Collection]: FilterCollectionOptionInversionMap,
});

export type FilterValue =
  | {
      value?: string | null;
      dataType: FilterDataType.String;
      filterOption?: FilterStringOption;
    }
  | {
      value?: string | null;
      dataType: FilterDataType.Uuid;
      filterOption?: FilterUuidOption;
    }
  | {
      value?: string | null;
      dataType: FilterDataType.Record;
      filterOption?: FilterRecordOptions;
    }
  | {
      value?: undefined;
      dataType: FilterDataType.Enum;
      filterOption?: FilterEnumOption.IsEmpty | FilterEnumOption.IsNotEmpty;
    }
  | {
      value: string | null;
      dataType: FilterDataType.Enum;
      filterOption?: FilterEnumOption.Is | FilterEnumOption.IsNot;
    }
  | {
      value: string[] | null;
      dataType: FilterDataType.Enum;
      filterOption?: FilterEnumOption.OneOf | FilterEnumOption.NotOneOf;
    }
  | {
      value?: string[] | null;
      dataType: FilterDataType.EnumMultiselect;
      filterOption?: FilterEnumMultiselectOption;
    }
  | {
      value?: number | null;
      dataType: FilterDataType.Number;
      filterOption?: FilterNumberOption;
    }
  | {
      value?: string | null;
      dataType: FilterDataType.Date;
      filterOption?: FilterDateOption;
    }
  | {
      value?: string | null;
      dataType: FilterDataType.Boolean;
      filterOption?: FilterBooleanOption;
    }
  | {
      value?: string | null;
      dataType: FilterDataType.Collection;
      filterOption?: FilterCollectionOption;
    };

export const stringOptions = mapStringArrayToOptions(
  Object.values(FilterStringOption),
);

export const uuidOptions = mapStringArrayToOptions(
  Object.values(FilterUuidOption),
);

export const recordOptions = mapStringArrayToOptions(
  Object.values(FilterRecordOptions),
);

export const enumOptions = mapStringArrayToOptions(
  Object.values(FilterEnumOption),
);

export const enumMultiselectOptions = mapStringArrayToOptions(
  Object.values(FilterEnumMultiselectOption),
);

export const numberOptions = mapStringArrayToOptions(
  Object.values(FilterNumberOption),
);

export const dateOptions = mapStringArrayToOptions(
  Object.values(FilterDateOption),
);

export const booleanOptions = mapStringArrayToOptions(
  Object.values(FilterBooleanOption),
);

export const collectionOptions = mapStringArrayToOptions(
  Object.values(FilterCollectionOption),
);

export const filterOptions = {
  [FilterDataType.String]: stringOptions,
  [FilterDataType.Uuid]: uuidOptions,
  [FilterDataType.Record]: recordOptions,
  [FilterDataType.Enum]: enumOptions,
  [FilterDataType.EnumMultiselect]: enumMultiselectOptions,
  [FilterDataType.Number]: numberOptions,
  [FilterDataType.Date]: dateOptions,
  [FilterDataType.Boolean]: booleanOptions,
  [FilterDataType.Collection]: collectionOptions,
};
