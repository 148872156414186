// We split this enum from the useFeatureFlags hook to avoid importing
// the posthog react package in the RSC context
export enum FeatureFlags {
  CustomFieldsUi = 'custom-fields-ui',
  Dashboards = 'hackathon-dashboards',
  ShowDeleteEnumValueOption = 'show-delete-enum-value-option',
  ShowDeleteCustomFieldsUi = 'show-delete-custom-field-ui',
  ShowCompanyAiSummary = 'show-ai-company-summary',
  ShowMeetingsUi = 'show-meetings-ui',
  ShowRenameEnumValueOption = 'show-rename-enum-value-option',
  EnableLiveUpdates = 'enable-live-updates',
  ShowCsvImportUi = 'show-csv-import-ui',
  EnableInternalFeatures = 'enable-internal-features',
  EnableMeetingRecording = 'enable-meeting-recording',
  EnableDealDetection = 'enable-deal-detection',
}
