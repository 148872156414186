import DOMPurify from 'dompurify';

export function stripHTMLFromString(html: string) {
  return DOMPurify.sanitize(html, {
    USE_PROFILES: {
      html: false,
    },
  });
}

export function sanitizeHTML(html: string) {
  return DOMPurify.sanitize(html);
}
